import ThemedContainer from '@aurora/shared-client/components/common/ThemedContainer/ThemedContainer';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import QuiltWrapperContext from '@aurora/shared-client/components/context/QuiltWrapperContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';
import useMeasureEnhanced from '@aurora/shared-client/components/useMeasureEnhanced';
import useSafeColor from '@aurora/shared-client/components/useSafeColor';
import type { CSSPropertiesWithVars } from '@aurora/shared-client/helpers/styles/CSSPropertiesWithVarsHelper';
import { NavbarPosition } from '@aurora/shared-generated/types/graphql-schema-types';
import { AuthFlow } from '@aurora/shared-client/types/enums';
import {
  BackgroundPosition,
  BackgroundRepeat,
  BackgroundSize
} from '@aurora/shared-types/images/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { CommonColorCssVariables } from '@aurora/shared-types/styles';
import { canUseDOM } from 'exenv';
import dynamic from 'next/dynamic';
import type { CSSProperties } from 'react';
import React, { useContext, useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { FrameContext } from 'react-frame-component';
import { useIntersection, useIsomorphicLayoutEffect } from 'react-use';
import { linkPropsToMenuLinkItems } from '../../../helpers/pageeditor/NavbarMenuLinkItemHelper';
import {
  getBackgroundImageUrl,
  getCssStyleFromProps
} from '../../../helpers/pageeditor/NavbarPropsHelper';
import AuthenticationLink from '../../authentication/AuthenticationLink/AuthenticationLink';
import useCommunityAccess from '../../authentication/AuthenticationLink/useCommunityAccess';
import EditContext from '../../context/EditContext/EditContext';
import PageEditorQuiltWrapperContext from '../../context/PageEditorQuiltWrapperContext/PageEditorQuiltWrapperContext';
import QuiltWrapperWidgetLocationContext from '../../context/QuiltWrapperWidgetContext/QuiltWrapperWidgetLocationContext';
import LanguagePicker from '../../languages/LanguagePicker/LanguagePicker';
import NavbarContext from '@aurora/shared-client/components/context/NavbarContext/NavbarContext';
import useWillNotesIconRender from '../../inbox/NotesIcon/useNotesIconWillRender';
import useWillNotificationsIconRender from '../../notifications/NotificationIcon/useNotificationsIconWillRender';
import SpotlightSearchIcon from '../../search/SpotlightSearchIcon/SpotlightSearchIcon';
import useDynamicCss from '../../useDynamicCss';
import useTranslation from '../../useTranslation';
import BrandLogo from '../BrandLogo/BrandLogo';
import NavbarAccountMenu from '../NavbarAccountMenu/NavbarAccountMenu';
import NavbarHamburgerDropdown from '../NavbarHamburgerDropdown/NavbarHamburgerDropdown';
import type { MenuLinkItem } from '../NavbarLink/NavbarLink';
import NavbarTextLinks from '../NavbarTextLinks/NavbarTextLinks';
import fromQuery from '../ThemeResult/ThemeResultAdapter';
import type { NavbarProps } from './NavbarWidget';
import localStyles from './Navbar.module.pcss';
import { SKIP_CONTENT } from './types';
import Head from 'next/head';
import MultiThemeContext from '@aurora/shared-client/components/context/MultiThemeContext/MultiThemeContext';
import { AppType } from '@aurora/shared-types/app';

const NotificationIcon = dynamic(
  () => import('../../notifications/NotificationIcon/NotificationIcon')
);

const NotesIcon = dynamic(() => import('../../inbox/NotesIcon/NotesIcon'));

interface Props {
  /** The component to render for the edit controls. Will always render if supplied. */
  EditControlSet?: React.FC<React.PropsWithChildren<unknown>>;

  /**
   * navbar props
   */
  navProps: NavbarProps;
}

/**
 * Renders the actual 'nav' element for Navbar.
 * @author Rosalyn Rowe, Willi Hyde, Luisina Santos
 */
const NavbarNav: React.FC<React.PropsWithChildren<Props>> = ({ EditControlSet, navProps }) => {
  const { canAccessCommunity } = useCommunityAccess();
  const notesIconWillRender = useWillNotesIconRender();
  const { formatMessage } = useTranslation(EndUserComponent.NAVBAR);
  const {
    publicConfig: { quiltsV2Enabled, multiAuthEnabled }
  } = useContext(TenantContext);
  const notificationsIconWillRender = useWillNotificationsIconRender();
  const { canRegister } = useContext(AppContext);
  const { document: frameDocument } = useContext(FrameContext);
  const {
    className,
    navbarVariantProps,
    navbarWidgetProps,
    useNavMenuItems,
    useTransparentNavbar
  } = navProps;
  const { theme } = useContext(ThemeContext);
  const tenant = useContext(TenantContext);
  const { endUserTheme } = useContext(MultiThemeContext);
  const safeColor = useSafeColor(fromQuery(theme, tenant));
  const style: CSSPropertiesWithVars = getCssStyleFromProps(navbarWidgetProps.style);
  const { quiltWrapper } = useContext(PageEditorQuiltWrapperContext);
  const quiltWrapperPublished = useContext(QuiltWrapperContext);
  const widgetLocation = useContext(QuiltWrapperWidgetLocationContext);
  const cx = useClassNameMapper(localStyles);
  const {
    style: { position: navbarPositionCssVar }
  } = navbarWidgetProps;
  const {
    backgroundImage,
    backgroundColor,
    backgroundOpacity,
    sideLinks,
    mainLinks,
    showRegisterLink,
    showUsername,
    showSearchIcon,
    showLanguagePicker,
    useIconLanguagePicker,
    useLabelLanguagePicker
  } = navbarVariantProps;

  const navbarRef = useRef<HTMLElement>();
  const spacerRef = useRef<HTMLDivElement>();
  const spyRef = useRef<HTMLDivElement>();
  const fixedNavClass = cx('lia-is-fixed');
  const styleScope = endUserTheme ? `[data-lia-styles=${AppType.END_USER}], :root` : ':root:root';

  /**
   * Determines if the navbar is in page builder.
   */
  const { showEditControls: isInPageBuilder } = useContext(EditContext);

  /**
   * Determines if the navbar is on the header/footer editor.
   */
  const { showEditControls: isInWrapperEditor } = useContext(PageEditorQuiltWrapperContext);

  /**
   * Determines if the navbar is fixed.
   */
  const isNavbarFixed = navbarPositionCssVar === NavbarPosition.Fixed && !isInWrapperEditor;

  /**
   * Theme Variables for the Navbar
   */
  const styleRulesFromProps = Object.entries(style)
    .map(([cssVar, value]) => `${cssVar}: ${value};`)
    .join(' ');

  /**
   * Sets the dynamic variables for navbar height.
   */
  const refSetter = useDynamicCss(
    (sheet, entry) => {
      const dynamicStyleRules = [];
      const navbarHeight = entry?.borderBoxSize?.[0]?.blockSize;

      dynamicStyleRules.push(
        `--lia-d-navbar-height-sticky: ${isNavbarFixed ? navbarHeight : '0'}px;`,
        `--lia-d-navbar-height: ${navbarHeight}px;`
      );

      const finalRules = dynamicStyleRules.join(' ');
      sheet.insertRule(`${styleScope} { ${finalRules} }`);
    },
    true,
    canUseDOM ? frameDocument : undefined
  );

  const { ref: spyRefMeasure, entry: spyEntry } = useMeasureEnhanced();
  useIsomorphicLayoutEffect(() => {
    if (spyEntry && isInPageBuilder && quiltsV2Enabled) {
      navbarRef.current.style.setProperty(
        '--lia-d-nav-fixed-width',
        `${spyEntry.contentRect.width}px`
      );
    }
  });

  const intersection = useIntersection(spyRef, { root: null, threshold: 0 });
  useIsomorphicLayoutEffect(() => {
    if (intersection && isNavbarFixed) {
      if (intersection.isIntersecting) {
        navbarRef.current.classList.remove(fixedNavClass);
        spacerRef.current.classList.remove(fixedNavClass);
        return;
      }
      navbarRef.current.classList.add(fixedNavClass);
      spacerRef.current.classList.add(fixedNavClass);
    }
  });

  let backgroundStyle: React.CSSProperties;
  if (backgroundImage) {
    const { assetName, backgroundPosition, backgroundSize, backgroundRepeat, lastModified } =
      backgroundImage;
    const assetUrl = getBackgroundImageUrl(
      quiltWrapper ? quiltWrapper.quiltWrapper : quiltWrapperPublished,
      tenant,
      theme,
      widgetLocation,
      assetName,
      lastModified
    );

    if (assetName) {
      backgroundStyle = {
        background: `url(${assetUrl}) ${BackgroundPosition[backgroundPosition]} / ${BackgroundSize[backgroundSize]} ${BackgroundRepeat[backgroundRepeat]}`
      };
      if (quiltsV2Enabled) {
        backgroundStyle.opacity = `${backgroundOpacity}`;
      }
    }
  }

  const backgroundColorFinal: CSSPropertiesWithVars = {
    '--lia-d-nav-bg-combined': quiltsV2Enabled
      ? backgroundImage?.assetName
        ? CommonColorCssVariables.TRANSPARENT
        : safeColor(backgroundColor)
            .alpha(quiltsV2Enabled ? backgroundOpacity : 1)
            .toString()
      : safeColor(backgroundColor).toString()
  };

  const finalShowSearchIcon = canAccessCommunity && showSearchIcon;

  const textLinkItems: MenuLinkItem[] = linkPropsToMenuLinkItems(mainLinks);
  const sideMenuItems: MenuLinkItem[] = linkPropsToMenuLinkItems(sideLinks);

  const { dropdownMenuOffset: themeDropdownMenuOffset, paddingBottom: themePaddingBottom } =
    theme.navbar;
  const { dropdownMenuOffset = themeDropdownMenuOffset, paddingBottom = themePaddingBottom } =
    navProps?.navbarWidgetProps.style ?? {};

  function getDropdownMenuPosition(
    offsetAmount: number = Number.parseInt(dropdownMenuOffset)
  ): number {
    return Number.parseInt(paddingBottom) - offsetAmount;
  }

  return (
    <NavbarContext.Provider value={getDropdownMenuPosition}>
      <Head>
        <style
          type="text/css"
          key="navbarRules"
        >{`${styleScope} { ${styleRulesFromProps} }`}</style>
      </Head>
      {isNavbarFixed && (
        <div
          ref={element => {
            spyRef.current = element;
            spyRefMeasure(element);
          }}
          aria-hidden="true"
        />
      )}
      <nav
        className={cx(
          'lia-nav',
          className,
          { 'lia-nav-fixed': isNavbarFixed },
          { 'lia-is-editing': EditControlSet },
          { 'lia-is-page-builder': isInPageBuilder || isInWrapperEditor },
          { 'lia-is-classic': !quiltsV2Enabled },
          { 'lia-has-transparent': useTransparentNavbar && !quiltsV2Enabled }
        )}
        style={
          {
            ...backgroundColorFinal
          } as CSSProperties
        }
        ref={element => {
          navbarRef.current = element;
          refSetter(element);
        }}
      >
        {EditControlSet && (
          <div className={cx('lia-edit-control-wrap')}>
            <EditControlSet />
          </div>
        )}
        {backgroundStyle && (
          <div className={cx('lia-nav-background')} tabIndex={-1} style={backgroundStyle} />
        )}
        <ThemedContainer className={cx('lia-container')} ignoreXsGutters>
          <NavbarHamburgerDropdown items={{ sideMenuItems, textLinkItems }} />
          <div className={cx('lia-brand-wrap')}>
            <BrandLogo className={cx('lia-brand-logo')} />
            <a href={`#${SKIP_CONTENT}`} className={cx('lia-skip-content')}>
              {formatMessage('skipContent')}
            </a>
          </div>
          <NavbarTextLinks items={textLinkItems} />
          {useNavMenuItems && (
            <section className={cx('lia-section lia-controls')}>
              {showLanguagePicker && (
                <LanguagePicker useIcon={useIconLanguagePicker} useLabel={useLabelLanguagePicker} />
              )}
              {finalShowSearchIcon && <SpotlightSearchIcon />}
              {notesIconWillRender && <NotesIcon />}
              {notificationsIconWillRender && <NotificationIcon />}
              {showRegisterLink && canRegister && (
                <AuthenticationLink
                  type={AuthFlow.REGISTRATION}
                  className={cx('lia-controller-text-link lia-g-navbar-link')}
                  textClassName={cx('lia-g-navbar-link-text')}
                />
              )}
              <AuthenticationLink
                className={cx('lia-controller-text-link lia-g-navbar-link')}
                textClassName={cx('lia-g-navbar-link-text')}
                type={multiAuthEnabled ? AuthFlow.MULTI_AUTH_LOGIN : AuthFlow.LOGIN}
              />
              <NavbarAccountMenu showUserName={showUsername} />
            </section>
          )}
        </ThemedContainer>
      </nav>
      {isNavbarFixed && <div ref={spacerRef} className={cx('lia-nav-spacer')} aria-hidden="true" />}
    </NavbarContext.Provider>
  );
};

export default NavbarNav;
